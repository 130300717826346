/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #f6f9fb;
  --ion-color-medium-rgb: 246, 249, 251;
  --ion-color-medium-contrast: #a29f9f;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.strock-icon {
  background: #dfdede;
  padding: 10px;
  border-radius: 50%;
  --ionicon-stroke-width:16px;
}

/**  MODALS CSS**/
.newrequestform {
  --font-family: Arial, Helvetica, sans-serif;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  --border-radius: 20px !important;
  --width:500px!important;
}
.newrequestform h2 {
  margin-left: 13px !important;
  font-weight: bolder;
  font-size: 14px !important;
}
.newrequestform .item-depart-list {
  margin-left: -17px !important;
}
.newrequestform .item-depart-list ion-avatar {
  margin-right: 7px !important;
  background: #D2DBFC;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newrequestform .item-depart-list ion-avatar ion-text {
  font-size: small;
  color: rgb(10, 10, 10);
}
.newrequestform .item-depart-list ion-label .item-label-title {
  font-weight: 600;
  color: blue;
}
.newrequestform .item-depart-list ion-label .secondpara {
  margin-top: -10px;
  color: grey;
}
.newrequestform .form {
  overflow: auto;
}
.newrequestform .form ion-segment {
  margin-left: 10px !important;
  width: 250px !important;
  padding: 5px !important;
  background-color: #eff2f3 !important;
  border-radius: 40px !important;
}
.newrequestform .form ion-segment ion-segment-button {
  top: 2.5px;
  --border-radius: 40px !important;
  color: rgb(173, 170, 170);
}
.newrequestform .form ion-item {
  --tw-text-opacity: 0.1!important;
  border: 1px solid rgb(86 84 84/var(--tw-text-opacity)) !important;
  border-radius: 10px;
  margin: 15px;
  background: 0 0;
  box-shadow: 0 8px 16px rgba(2, 8, 20, 0.1), 0 4px 8px rgba(2, 8, 20, 0.08);
  font-size: small;
}
.newrequestform .form .buttons-group {
  float: right;
}
.newrequestform .form .buttons-group .lightbutton {
  justify-content: center;
  width: 100px;
  font-size: small;
  border-radius: 40px;
  background: 0 0;
  box-shadow: 0 8px 16px rgba(2, 8, 20, 0.1), 0 4px 8px rgba(2, 8, 20, 0.08);
  color: var(--ion-color-dark);
}
.newrequestform .form .buttons-group .submitbutton {
  justify-content: center;
  width: 100px;
  font-size: small;
  border-radius: 40px;
  background-color: #00b2f7;
  color: #ffffff;
}

.buttons-group {
  float: right;
}
.buttons-group .lightbutton {
  justify-content: center;
  width: 100px;
  font-size: small;
  border-radius: 40px;
  background: 0 0;
  box-shadow: 0 8px 16px rgba(2, 8, 20, 0.1), 0 4px 8px rgba(2, 8, 20, 0.08);
  color: var(--ion-color-dark);
}
.buttons-group .submitbutton {
  justify-content: center;
  width: 100px;
  font-size: small;
  border-radius: 40px;
  background-color: #00b2f7;
  color: #ffffff;
}

.no-border {
  border: none;
}

.modal-500-width {
  --width:500px !important;
}

.modal-border-radius-20 {
  --border-radius:20px!important;
}

.modal-300-width {
  --width:300px !important;
}

.modal-border-radius-10 {
  --border-radius:10px!important;
}

/** END OF MODALS CSS **/
.cursor-pointer {
  cursor: pointer;
}

.font-size-100 {
  font-size: 100px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.margin-auto {
  margin: auto;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-9 {
  font-size: 9px !important;
}

ion-segment {
  margin: 0px !important;
  padding: -10px !important;
}
ion-segment ion-segment-button {
  position: relative;
  margin-top: -5px !important;
}
ion-segment ion-segment-button ion-item ion-label {
  font-size: 12px !important;
}
ion-segment ion-segment-button ion-tab-button ion-label {
  font-size: 12px !important;
}
ion-segment ion-segment-button .carre {
  position: absolute;
  background-color: var(--ion-color-dark);
  width: 17px;
  height: 17px;
  z-index: 5;
  top: -8px;
  transform: rotate(45deg);
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
  .modal-border-radius-20 {
    border-radius: 0px !important;
  }
  .smalldevicehidden {
    display: none !important;
  }
  .smalldevicevisually {
    display: block !important;
  }
  .full-width-small-device {
    width: 100%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .modal-border-radius-20 {
    border-radius: 0px !important;
  }
  .smalldevicehidden {
    display: none !important;
  }
  .smalldevicevisually {
    display: block !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .smalldevicehidden {
    display: block !important;
  }
  .largedevicehidden {
    display: none !important;
  }
}
.visible {
  display: block;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.detailrequestheader {
  text-align: center;
}
.detailrequestheader ion-avatar {
  width: 120px;
  height: 120px;
  background-color: #50c8ff;
  justify-content: center;
  display: flex;
  align-items: center;
}
.detailrequestheader ion-avatar ion-icon {
  width: 200px;
  color: white;
}
.detailrequestheader h2 {
  margin-bottom: -5px !important;
}

.sm-h2-title {
  font-size: smaller;
}

.contentbody {
  --background:#f6f9fb;
}

.contentbody-margin {
  margin-top: -0.8px !important;
}

.detailrequestheader2 {
  text-align: center;
}
.detailrequestheader2 h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.detailrequestheader2 h2 ion-text {
  font-size: smaller;
}
.detailrequestheader2 ion-avatar {
  width: 70px;
  height: 70px;
  background-color: #50c8ff;
  justify-content: center;
  display: flex;
  align-items: center;
}
.detailrequestheader2 ion-avatar ion-icon {
  width: 20px;
  color: white;
}

.height-100 {
  height: 100vmax;
}

.height-100h {
  height: 100vh;
}

.min-height-100h {
  min-height: 100vmax;
}

.list-img-item:hover {
  background-color: var(--ion-color-success);
}

.list-img {
  --background: var(--ion-color-dark-tint);
}
.list-img ion-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--ion-color-medium-contrast);
  border-radius: 10px;
}
.list-img ion-avatar ion-img {
  width: 40px;
  height: 40px;
}
.list-img ion-chip {
  width: 55px;
  height: 25px;
  --background: var(--ion-color-secondary);
}
.list-img ion-label {
  margin-top: 25px;
}
.list-img ion-label .item-label-title {
  font-size: 13px;
  font-weight: 500px;
  color: grey;
}
.list-img ion-label .secondpara {
  margin-top: -10px !important;
}

.list-img-item-selected {
  background-color: var(--ion-color-success);
}

.img-viewer {
  max-width: 100vmax;
  max-height: max-content;
}

.hidden {
  display: none;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .largedevicehidden {
    display: none !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .largedevicehidden {
    display: none !important;
  }
}
.searchbar {
  width: 40vw !important;
}

.myactionsheet {
  --background:rgb(246, 249, 251) ;
}

.align-center {
  text-align: center;
}

.menuleft {
  height: 100vh;
}
.menuleft .menu-item {
  --padding-bottom:1px!important;
  --background: #cdcdcd;
}
.menuleft .menu-item ion-avatar {
  width: 40px;
  height: 40px;
  margin-right: 13px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.menuleft .menu-item ion-avatar ion-icon {
  color: white;
}
.menuleft .menu-item ion-label {
  margin-top: 20px;
}
.menuleft .menu-item ion-label .secondpara {
  font-weight: 400;
  margin-top: -10px !important;
  font-size: 10px !important;
}
.menuleft .menu-item ion-label .item-label-title {
  font-weight: 600;
  font-size: 13px !important;
  color: rgb(39, 38, 38);
}
.menuleft .menu-item ion-label p {
  color: rgb(158, 155, 155);
}
.menuleft .menu-item ion-label p span {
  padding: 0 5px 0 0;
}
.menuleft .menu-item ion-label p .point {
  font-size: 2em;
}
.menuleft .menu-item ion-label p .reqtotalprinted {
  color: #50c8ff;
}

.leftmenu {
  background-color: rgb(246, 249, 251) !important;
}
.leftmenu ion-accordion-group ion-accordion .side-menu ion-item {
  text-align: left;
  margin-left: 10px;
  font-size: 11px !important;
  background-color: rgb(246, 249, 251) !important;
}
.leftmenu ion-accordion-group ion-accordion .side-menu ion-item ion-avatar {
  background-color: #1f72c5;
  color: white;
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.leftmenu ion-accordion-group ion-accordion .side-menu ion-item ion-avatar ion-text {
  margin: auto;
  font-size: 10px !important;
}
.leftmenu ion-accordion-group ion-accordion .side-menu ion-item ion-tab-button {
  display: flex;
  align-content: flex-start;
  --background:white ;
}
.leftmenu ion-accordion-group ion-accordion .side-menu ion-item ion-tab-button ion-icon {
  width: 15px;
  height: 15px;
}
.leftmenu ion-accordion-group ion-accordion .side-menu ion-item ion-tab-button ion-label {
  font-size: 10px !important;
}

.margin-left-moins-20 {
  margin-left: -20px;
}

.margin-left-moins-25 {
  margin-left: -25;
}

.demo-section + .demo-section {
  margin-top: 16px;
}

.secondbackground {
  background-color: rgb(246, 249, 251);
}

.ionsuccess {
  background-color: #28ba62;
  color: #f4f5f8;
}

.align-left {
  margin-left: -30px;
}

.arrowback {
  float: left;
}

.hr {
  background-color: #babdc0;
}

.hr-gray {
  background-color: #d7d8da;
}

.fit-contents {
  width: max-content;
}

.no-data-img {
  width: 50vw;
  height: 30vw;
}

.success-border-radius {
  border: 3px solid var(--ion-color-success-tint);
}

.danger {
  color: var(--ion-color-danger);
}

.danger-border-radius {
  border: 3px solid var(--ion-color-danger-tint);
}

.light-border-radius {
  border: 3px solid var(--ion-color-light-tint);
}

.warning-border-radius {
  border: 3px solid var(--ion-color-warning-tint);
}

.bg-medium-tint {
  background: #505253;
}

.bg-success {
  background-color: var(--ion-color-success);
}

.bg-white {
  background-color: var(--ion-color-light);
}

.bg-light {
  background-color: var(--ion-color-light);
}

.bg-gray {
  background: #babdc0;
}

.bg-sm-gray {
  background-color: rgb(245, 245, 245);
}

.item-bg-sm-gray {
  --background: rgb(245, 245, 245);
}

.bg-dark {
  background-color: var(--ion-color-dark);
}

.bg-tertiary {
  background-color: #8ad1ee;
}

.bg-tint {
  background-color: var(--ion-color-dark-tint);
}

.bg-primary {
  background-color: var(--ion-color-primary);
}

.bg-danger {
  background-color: var(--ion-color-danger);
}

.bg-tertiary {
  background-color: var(--ion-color-tertiary);
}

.horizontal-scrol {
  overflow-x: auto;
  scroll-snap-type: mandatory;
}

.bg-medium {
  background-color: var(--ion-color-medium);
}

.header-title .item-label-title {
  font-size: larger;
  color: rgb(33, 109, 239);
}
.header-title .secondpara2 {
  font-size: small;
  margin-top: -10px;
}

.list-bg-kaki {
  --background:#F4F6F5;
}

.list-bg-kaki:hover {
  --background:#dcf9eb;
}

.header-bloc {
  margin-bottom: -10px !important;
}

.bg-kaki {
  background-color: #F4F6F5;
}

.bg-blue-dark {
  background: #243859;
}

.ion-bg-blue-dark {
  --background:#243859;
}

.ion-bg-kaki {
  --background-color: #F4F6F5;
}

.bg-secondary {
  background-color: var(--ion-color-secondary);
}

.item-color-medium-tint {
  --background:#505253;
  --color:var(--ion-color-light);
}
.item-color-medium-tint ion-note {
  --color:var(--ion-color-light-tint);
}

.box-big {
  background: 0 0;
  box-shadow: 0 8px 16px rgba(2, 8, 20, 0.1), 0 4px 8px rgba(2, 8, 20, 0.08);
  border-radius: 40px;
  box-sizing: content-box;
  transition: 0.2s all ease-out;
}

.light-shadow {
  background: 0 0;
  box-shadow: 0 8px 16px rgba(2, 8, 20, 0.1), 0 4px 8px rgba(2, 8, 20, 0.08);
}

.light-shadow2 {
  background: 0 0;
  box-shadow: 0 2px 4px rgba(2, 4, 10, 0.08), 0 2px 4px rgba(1, 4, 10, 0.08);
}

.buttons-list {
  width: fit-content;
  height: 65px !important;
  --tw-text-opacity: 0.1!important;
  background-color: var(--ion-color-dark-tint);
  border: 1px solid rgb(86 84 84/var(--tw-text-opacity)) !important;
  border-radius: 10px;
}
.buttons-list ion-tab-button {
  margin-top: 10px;
  background-color: transparent !important;
}
.buttons-list ion-tab-button ion-avatar {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons-list ion-tab-button ion-avatar ion-icon {
  width: 20px;
  height: 20px;
}

.bd-radius-10 {
  border-radius: 10px;
}

.bd-radius-20 {
  border-radius: 20px;
}

.bd-rounded {
  --tw-text-opacity: 0.1!important;
  border: 1px solid rgb(86 84 84/var(--tw-text-opacity)) !important;
  border-radius: 20px;
}

.bd-3px {
  border: 3px solid var(--ion-color-primary-tint) !important;
}

.bd-square {
  --tw-text-opacity: 0.1!important;
  border: 1px solid rgb(86 84 84/var(--tw-text-opacity)) !important;
}

.emailmsgbody {
  height: 100vh;
}

ion-icon.mediumcolor {
  color: #b6b1b1;
}

.blocheader {
  text-align: center;
}
.blocheader h2 {
  margin-bottom: -5px;
}
.blocheader ion-item {
  margin-left: 230px !important;
}
.blocheader ion-avatar {
  margin-bottom: 5px !important;
  border: 10px solid yellow;
  width: 120px;
  height: 120px;
  background-color: #50c8ff;
  justify-content: center;
  display: flex;
  align-items: center;
}
.blocheader ion-avatar ion-icon {
  width: 50px;
  height: 50px;
  color: white;
}

.blocheadersuccess {
  text-align: center;
}
.blocheadersuccess h2 {
  margin-bottom: -5px;
}
.blocheadersuccess ion-item {
  margin-left: 230px !important;
}
.blocheadersuccess ion-avatar {
  margin-bottom: 5px !important;
  border: 5px solid green;
  width: 120px;
  height: 120px;
  background-color: #50c8ff;
  justify-content: center;
  display: flex;
  align-items: center;
}
.blocheadersuccess ion-avatar ion-icon {
  width: 50px;
  height: 50px;
  color: white;
}

.userprofiltoedit {
  text-align: center;
}
.userprofiltoedit h2 {
  margin-bottom: -5px;
}
.userprofiltoedit ion-item {
  margin-left: 230px !important;
}
.userprofiltoedit ion-avatar {
  margin-bottom: 5px !important;
  width: 120px;
  height: 120px;
  background-color: #50c8ff;
  justify-content: center;
  display: flex;
  align-items: center;
}
.userprofiltoedit ion-avatar ion-icon {
  width: 50px;
  height: 50px;
  color: white;
}
.userprofiltoedit ion-avatar.section2 {
  margin-top: -50px !important;
  margin-right: 300px !important;
  width: 40px;
  height: 40px;
  background-color: var(--ion-color-success);
  justify-content: center;
  display: flex;
  align-items: center;
}
.userprofiltoedit ion-avatar.section2 ion-icon {
  width: 20px;
  height: 20px;
  color: white;
}

.smallavatar ion-avatar {
  width: 25px;
  height: 25px;
  background: #d7d5d5;
}
.smallavatar ion-avatar ion-icon {
  width: 15px;
  height: 15px;
}

.middleavatar ion-avatar {
  width: 35px;
  height: 35px;
  background: #d7d5d5;
}
.middleavatar ion-avatar ion-icon {
  width: 20px;
  height: 20px;
}

.over-light {
  --color-activated:var(--ion-color-dark-tint);
  --background:rgb(179, 171, 171);
  --background-hover: var(--ion-color-medium-tint);
  --color-hover: var(--ion-color-light);
}

ion-action-sheet.my-custom-actionsheet {
  --background: #f58840!important;
  --backdrop-opacity: 0.6;
  --button-background-selected: #e97223;
  --button-color: #000000;
  --color: #fff;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #000000;
}

.very-small-icon {
  width: 13px !important;
  height: 13px !important;
}

.font-bold-500 {
  font-weight: 500;
}

.font-bold-600 {
  font-weight: 600;
}

.hgt-10 {
  height: 50px;
}

.listrequests {
  margin-top: -23px;
}

.list-fab-buttons ion-buttons ion-button {
  border-radius: 50%;
  margin-right: 60px;
}
.list-fab-buttons ion-buttons ion-button mat-icon {
  color: var(--ion-color-light);
}

.list-item-no-btn-margin ion-item ion-avatar {
  width: 45px;
  height: 45px;
  background-color: #50c8ff;
  margin-right: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.list-item-no-btn-margin ion-item ion-avatar ion-text {
  font-size: 10px !important;
  font-weight: 600;
  color: white;
}
.list-item-no-btn-margin ion-item ion-avatar ion-icon {
  font-weight: 600;
  color: white;
}
.list-item-no-btn-margin ion-item ion-label {
  margin-left: 0px;
  margin-top: 15px;
}
.list-item-no-btn-margin ion-item ion-label .secondpara {
  margin-top: -5px !important;
  font-size: 11px;
  color: grey;
}
.list-item-no-btn-margin ion-item ion-label .item-label-title {
  font-size: 13px;
  font-weight: 500;
  color: black;
}
.list-item-no-btn-margin ion-item ion-label p {
  color: grey;
}
.list-item-no-btn-margin ion-item ion-label p span {
  padding: 0 5px 0 0;
}
.list-item-no-btn-margin ion-item ion-label p .point {
  font-size: 2em;
  color: #50c8ff;
}
.list-item-no-btn-margin ion-item ion-label p .reqtotalprinted {
  color: #50c8ff;
}

/* Animation for requests List */
@keyframes request-list-animation {
  from {
    border: 1px solid var(--ion-color-secondary);
  }
  to {
    border: 2px solid var(--ion-color-medium);
  }
}
.sticky {
  position: sticky;
}

.requestslist {
  overflow-x: scroll;
  overscroll-behavior-block: contain;
  height: 75vh !important;
  scrollbar-width: thin;
  overflow-anchor: none;
}
.requestslist ion-item:hover {
  --background: #eef1f2;
}

.selected-item-primary-sq {
  border-radius: 20px;
  box-shadow: #1e2023;
  border: 3px solid var(--ion-color-secondary);
  --background: #f4f5f6;
}

.list-item ion-item ion-avatar {
  width: 45px;
  height: 45px;
  background-color: #50c8ff;
  margin-right: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.list-item ion-item ion-avatar ion-icon {
  font-weight: 600;
  color: white;
}
.list-item ion-item ion-label .secondpara {
  margin-top: -30px !important;
  font-size: 11px;
}
.list-item ion-item ion-label .item-label-title {
  font-size: 13px;
  font-weight: 500;
  color: black;
}
.list-item ion-item ion-label p {
  color: grey;
}
.list-item ion-item ion-label p span {
  padding: 0 5px 0 0;
}
.list-item ion-item ion-label p .point {
  font-size: 2em;
  color: #50c8ff;
}
.list-item ion-item ion-label p .reqtotalprinted {
  color: #50c8ff;
}
.list-item ion-item ion-label p.secondpara {
  margin-top: -130px !important;
}

.display-flex-center {
  justify-content: center;
  display: flex;
  align-items: center;
}

.list-notifications ion-avatar {
  background: #383a3e;
}
.list-notifications ion-item:hover {
  --background: #c6c7ca;
}
.list-notifications ion-item:hover .secondpara {
  color: var(--ion-color-dark-shade);
  font-weight: bolder;
}

.autocomplete-list-item {
  margin-bottom: 10px !important;
}
.autocomplete-list-item :hover {
  background-color: #c6c7ca;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 4px;
}
.autocomplete-list-item ion-avatar {
  width: 38px;
  height: 38px;
  background-color: rgb(120, 18, 107);
  margin-right: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.autocomplete-list-item ion-avatar ion-icon {
  font-weight: 600;
  color: white;
}
.autocomplete-list-item ion-label {
  margin-top: 20px !important;
}
.autocomplete-list-item ion-label .secondpara {
  margin-top: -18px;
}
.autocomplete-list-item ion-label .item-label-title {
  padding-top: 2px;
  font-weight: 500;
  color: black;
}
.autocomplete-list-item ion-label p {
  color: grey;
}
.autocomplete-list-item ion-label p span {
  padding: 0 5px 0 0;
}
.autocomplete-list-item ion-label p .point {
  font-size: 2em;
  color: #50c8ff;
}

.default-list-item-light ion-avatar {
  width: 45px;
  height: 45px;
  background-color: #50c8ff;
  margin-right: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.default-list-item-light ion-avatar ion-icon {
  font-weight: 600;
  color: white;
}
.default-list-item-light ion-label .secondpara {
  margin-top: -12px !important;
  font-size: 11px;
  color: var(--ion-color-light-tint);
}
.default-list-item-light ion-label .item-label-title {
  font-size: 13px;
  font-weight: 500;
  color: var(--ion-color-light-tint);
}

.condensed-item ion-label {
  margin-top: 24px;
}
.condensed-item ion-label .secondpara {
  margin-top: -12px !important;
  font-size: 11px;
  color: var(--ion-color-dark-shade);
}

.ion-icon-width-20 ion-icon {
  width: 20px;
  height: 20px;
}

.request-list-item ion-avatar {
  width: 45px;
  height: 45px;
  background-color: #50c8ff;
  margin-right: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.request-list-item ion-avatar ion-icon {
  font-weight: 600;
  color: white;
}
.request-list-item ion-label {
  margin-top: 24px;
}
.request-list-item ion-label .secondpara {
  margin-top: -12px !important;
  font-size: 11px;
  color: var(--ion-color-dark-shade);
}
.request-list-item ion-label .item-label-title {
  font-size: 13px;
  font-weight: 500;
  color: var(--ion-color-dark-tint);
}

.othersmenus {
  background: 0 0;
  box-shadow: 0 2px 4px rgba(1, 4, 5, 0.05), 0 4px 8px rgba(2, 8, 20, 0.08);
}

.router-outlet-bloc {
  border-top-right-radius: 40px !important;
  border-top-left-radius: 40px !important;
  background: 0 0;
  box-shadow: 0 2px 4px rgba(1, 4, 5, 0.05), 0 4px 8px rgba(2, 8, 20, 0.08);
}

.default-list-item ion-avatar {
  width: 45px;
  height: 45px;
  background-color: #50c8ff;
  margin-right: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.default-list-item ion-avatar ion-icon {
  font-weight: 600;
  color: white;
}
.default-list-item ion-label {
  margin-top: 24px;
}
.default-list-item ion-label .secondpara {
  margin-top: -12px !important;
  font-size: 11px;
}
.default-list-item ion-label .item-label-title {
  font-size: 13px;
  font-weight: 500;
  color: black;
}
.default-list-item ion-label p {
  color: grey;
}
.default-list-item ion-label p span {
  padding: 0 5px 0 0;
}
.default-list-item ion-label p .point {
  font-size: 2em;
  color: #50c8ff;
}

.underlinehover:hover {
  text-decoration: underline;
}

.margin-right {
  margin-right: 5px;
}

.right-bloc {
  position: relative;
  z-index: 10;
}

.menu-item {
  --background: #cdcdcd;
}
.menu-item ion-avatar {
  width: 40px;
  height: 40px;
  margin-right: 13px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.menu-item ion-avatar ion-icon {
  color: white;
}
.menu-item ion-label {
  margin-top: 20px;
}
.menu-item ion-label .secondpara {
  font-weight: 400;
  margin-top: -10px !important;
  font-size: 10px !important;
}
.menu-item ion-label .item-label-title {
  font-weight: 600;
  font-size: 13px !important;
  color: rgb(39, 38, 38);
}
.menu-item ion-label p {
  color: rgb(158, 155, 155);
}
.menu-item ion-label p span {
  padding: 0 5px 0 0;
}
.menu-item ion-label p .point {
  font-size: 2em;
}
.menu-item ion-label p .reqtotalprinted {
  color: #50c8ff;
}

.padding-top-moins-5 {
  margin-top: -5px !important;
}

.padding-top-moins-10 {
  margin-top: -10px !important;
}

.padding-top-moins-13 {
  margin-top: -13px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.logoapp {
  display: flex;
  justify-content: center;
}

.margin-btm-10 {
  margin-bottom: 10px;
}

.margin-btm-5 {
  margin-bottom: 5px;
}

.margin-btm-15 {
  margin-bottom: 15px;
}

.margin-btm-25 {
  margin-bottom: -25px;
}

mat-nav-list a {
  background: #e7edf1;
  padding: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-moins-10 {
  margin-top: -10px;
}

.margin-top-moins-20 {
  margin-top: -20px;
}

.margin-5 {
  margin: 5px;
}

.justify-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  text-align: center;
}

.color-customer-warn {
  color: #faae77;
  font-weight: bolder;
}

.square-avatar-primary {
  border: 1px solid hsl(284deg, 79%, 78%);
  border-radius: 5px !important;
  background: #3880ff !important;
  width: 30px !important;
  height: 30px !important;
}
.square-avatar-primary mat-icon {
  color: #f4f5f8;
}
.square-avatar-primary ion-icon {
  color: #f4f5f8;
}

.square-avatar-warning {
  border: 1px solid #72adcd;
  border-radius: 5px !important;
  background: #ffc409 !important;
  width: 25px !important;
  height: 25px !important;
}
.square-avatar-warning mat-icon {
  width: 25px !important;
  height: 25px !important;
  color: #f4f5f8;
}
.square-avatar-warning ion-icon {
  width: 25px !important;
  height: 25px !important;
  color: #f4f5f8;
}

.success-border-rounded-avatar {
  border: 1px solid #E1EFFA;
  background-color: var(--ion-color-success-shade);
  width: 40px;
  height: 40px;
}

.danger-border-rounded-avatar {
  border: 1px solid #E1EFFA;
  background-color: var(--ion-color-danger-tint);
  width: 40px;
  height: 40px;
}

.border-rounded-avatar {
  border: 1px solid #E1EFFA;
  background-color: #E1EFFA;
  width: 40px;
  height: 40px;
}

.square-avatar-secondary {
  border: 1px solid #72adcd;
  border-radius: 5px !important;
  background: var(--ion-color-secondary) !important;
  width: 25px !important;
  height: 25px !important;
}
.square-avatar-secondary mat-icon {
  width: 25px !important;
  height: 25px !important;
  color: #f4f5f8;
}
.square-avatar-secondary ion-icon {
  width: 25px !important;
  height: 25px !important;
  color: #f4f5f8;
}

.square-avatar-medium {
  border: 1px solid #72adcd;
  border-radius: 5px !important;
  background: var(--ion-color-medium) !important;
  width: 25px !important;
  height: 25px !important;
}
.square-avatar-medium mat-icon {
  width: 25px !important;
  height: 25px !important;
  color: #f4f5f8;
}
.square-avatar-medium ion-icon {
  width: 25px !important;
  height: 25px !important;
  color: #f4f5f8;
}

.small-avatar {
  width: 30px !important;
  height: 30px !important;
}

.square-avatar-success {
  border: 1px solid #ef418f;
  border-radius: 5px !important;
  background: #28ba62 !important;
  width: 30px !important;
  height: 30px !important;
}
.square-avatar-success mat-icon {
  color: #f4f5f8;
}
.square-avatar-success ion-icon {
  color: #f4f5f8;
}

.square-avatar-danger {
  border: 1px solid #504d4d;
  border-radius: 5px !important;
  background: #cf3c4f !important;
  width: 30px !important;
  height: 30px !important;
}
.square-avatar-danger mat-icon {
  color: #f4f5f8;
}
.square-avatar-danger ion-icon {
  color: #f4f5f8;
}

ion-card ion-item ion-label h4 {
  color: grey;
}
ion-card ion-item ion-label h4 span {
  padding: 5px;
}
ion-card ion-item ion-label h4 .point {
  font-size: 2em;
}
ion-card ion-item ion-label h4 .reqtotalprinted {
  color: #50c8ff;
}

ion-tab-button ion-chip ion-icon {
  width: 20px;
  height: 20px;
  margin: auto;
}

.full-heigth {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.middlesize {
  text-transform: capitalize;
}

.defaultbackgound {
  background-color: #50c8ff;
}

.italic {
  font-style: italic;
}

.height-80vh {
  height: 80vh;
}

.segment-scrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: thin;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.container {
  height: 80vh;
  overflow-x: scroll;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}